<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="pl-5 pr-5">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <!-- factory name -->
            <v-row>
              <v-col
                class="align-center d-flex"
                cols="2"
              >
                <div>
                  <h3>{{ $t('messages.factoryName') }}</h3>
                </div>
              </v-col>
              <v-col cols="10">
                <v-row>
                  <v-col>
                    <div class="align-self-center mr-9 mt-4">
                      <v-text-field
                        :value="nameCustomer"
                        dense
                        single-line
                        outlined
                        disabled
                        clearable
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- date picker -->
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col
                class="align-center d-flex mb-6"
                cols="2"
              >
                <div>
                  <h3>{{ $t('messages.date_range') }}</h3>
                </div>
              </v-col>
              <!-- Single Date option selected -->
              <v-col cols="10">
                <div class="align-self-center mr-9">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    disabled
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      locale="ja-jn"
                      no-title
                      scrollable
                      disabled
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        {{ $t('messages.cancel') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        {{ $t('messages.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <!-- Range Date option selected -->
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" />
          <v-col
            v-if="userType === 3"
            cols="5"
          >
            <div>
              <h2 class="nameFile">
                {{ timesheetName }}
              </h2>
            </div>
          </v-col>
          <v-col
            v-else
            cols="5"
          >
            <div
              v-if="!editName"
              @click="editName = true"
            >
              <h2 class="nameFile">
                {{ timesheetName }}
              </h2>
            </div>
            <div v-else>
              <validation-observer>
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="nameFile"
                  >
                    <v-text-field
                      v-model="timesheetName"
                      :error-messages="errors[0]"
                      @keydown.enter="editName = !timesheetName"
                      @blur="editName = !timesheetName"
                    />
                  </validation-provider>
                </v-form>
              </validation-observer>
            </div>
          </v-col>
          <v-col cols="3" />
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-end"
          >
            <div class="text-center">
              <span>署名</span>
              <signature-pad
                :src-image="signature"
                :is-submitted="isSubmitted || userType !== 3"
                @getSignature="getSignature"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :options.sync="options"
              :items="timesheet.timesheetRecords"
              :loading-text="$t('table.messages.loading')"
              :loading="loading"
              :items-per-page="10"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 100],
                showFirstLastPage: false,
                'items-per-page-text': $t('table.messages.items_per_page'),
              }"
              :no-data-text="$t('table.messages.no_data')"
            >
              <template v-slot:header.normalWorktime="props">
                <span class="highlights">
                  {{ props.header.text }}
                </span>
              </template>

              <template v-slot:header.nightShiftWorktime="props">
                <span class="highlights">
                  {{ props.header.text }}
                </span>
              </template>

              <template v-slot:header.otWorktime="props">
                <span class="highlights">
                  {{ props.header.text }}
                </span>
              </template>

              <template
                v-if="isOtNightShift"
                v-slot:header.otNightShiftWorktime="props"
              >
                <span class="highlights">
                  {{ props.header.text }}
                </span>
              </template>

              <template
                v-slot:footer.page-text
                class="mr-0"
              >
                {{ $t('table.messages.page') }}
                {{ options.page }}
              </template>

              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-center">
                    {{ getIndex(index) }}
                  </td>
                  <td class="text-center">
                    <span class="font-weight-bold">{{ getValueObject(item, 'parttimeEmployeeManageId') }}</span>
                  </td>
                  <td class="text-center">
                    <span class="font-weight-bold">{{ getValueObject(item, 'parttimeEmployeeName') }}</span>
                  </td>
                  <td>
                    <div
                      v-if="item.shiftStartAt"
                      class="textJob font-weight-bold"
                    >
                      <p>{{ getValueObject(item, 'jobName') }}</p>
                      <p>({{ getValueObject(item, 'shiftStartAt') + ' ~ ' + getValueObject(item, 'shiftEndAt') }})</p>
                    </div>
                    <div
                      v-else
                      class="textJob font-weight-bold"
                    >
                      <span>{{ getValueObject(item, 'jobName') }}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="userType !== 3"
                      class="attendanceConfirmation"
                      @click="checkIsPresent(item)"
                    >
                      <span v-if="getValueObject(item, 'isPresent') !== false">
                        <i class="mdi mdi-radiobox-blank" />
                      </span>
                      <span v-else>
                        <i class="attendancConfirm mdi mdi-radiobox-marked" />
                      </span>
                    </span>
                    <span
                      v-else
                      class="attendanceConfirmation"
                    >
                      <span v-if="getValueObject(item, 'isPresent') !== false">
                        <i class="mdi mdi-radiobox-blank" />
                      </span>
                      <span v-else>
                        <i class="attendancConfirm mdi mdi-radiobox-marked" />
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="userType !== 3"
                      :class="{'errorBreakTime': checkBreakTime(item)}"
                    >
                      <cell-edit-time
                        v-if="getValueObject(item, 'isPresent') !== false"
                        :value="getValueObject(item, 'actualStartAt')"
                        :check-break-time="true"
                        prop="actualStartAt"
                        :item="item"
                        @save="save"
                        @erorrValidate="showErorr"
                      />
                    </span>
                    <span v-else>
                      <span v-if="getValueObject(item, 'isPresent') !== false">
                        {{ getValueObject(item, 'actualStartAt') }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="userType !== 3"
                      :class="{'errorBreakTime': checkBreakTime(item)}"
                    >
                      <cell-edit-time
                        v-if="getValueObject(item, 'isPresent') !== false"
                        :value="getValueObject(item, 'actualEndAt')"
                        :check-break-time="true"
                        prop="actualEndAt"
                        :item="item"
                        @save="save"
                        @erorrValidate="showErorr"
                      />
                    </span>
                    <span v-else>
                      <span v-if="getValueObject(item, 'isPresent') !== false">
                        {{ getValueObject(item, 'actualEndAt') }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="getValueObject(item, 'isPresent') !== false"
                      :class="{'errorBreakTime':!getValueObject(item, 'checkBreakTime', true)}"
                    >
                      {{ convertTime(getValueObject(item, 'normalWorktime')) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="getValueObject(item, 'isPresent') !== false"
                      :class="{'errorBreakTime':!getValueObject(item, 'checkBreakTime', true)}"
                    >
                      {{ convertTime(getValueObject(item, 'nightShiftWorktime')) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="getValueObject(item, 'isPresent') !== false"
                      :class="{'errorBreakTime':!getValueObject(item, 'checkBreakTime', true)}"
                    >
                      {{ convertTime(getValueObject(item, 'otWorktime')) }}
                    </span>
                  </td>
                  <td
                    v-if="isOtNightShift"
                    class="text-center"
                  >
                    <span
                      v-if="getValueObject(item, 'isPresent') !== false"
                      :class="{'errorBreakTime':!getValueObject(item, 'checkBreakTime', true)}"
                    >
                      {{ convertTime(getValueObject(item, 'otNightShiftWorktime', 0)) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="userType !== 3"
                      :class="{'errorBreakTime': checkBreakTime(item)}"
                    >
                      <cell-edit-time
                        v-if="getValueObject(item, 'isPresent') !== false"
                        :value="getValueObject(item, 'breaktime')"
                        :check-break-time="true"
                        prop="breaktime"
                        :item="item"
                        @save="save"
                        @erorrValidate="showErorr"
                      />
                    </span>
                    <span v-else>
                      <span v-if="getValueObject(item, 'isPresent') !== false">
                        {{ convertTime(getValueObject(item, 'breaktime')) }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span v-if="getValueObject(item, 'isPresent') !== false">
                      {{ convertTime(getValueObject(item, 'totalWorkHour')) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <v-edit-dialog>
                      <span
                        v-if="getValueObject(item, 'isPresent') !== false"
                        class="font-weight-bold textName"
                      >
                        {{ item.note }}
                      </span>
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.note"
                          single-line
                          counter
                          @keydown.enter.prevent="saveNote(item)"
                          @blur="saveNote(item)"
                        />
                      </template>
                    </v-edit-dialog>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div
      v-if="!isSubmitted || userType !== 3"
      class="d-flex justify-space-between"
    >
      <v-row class="d-flex justify-end mt-3">
        <v-col
          v-if="isUpdate && userType !== 3"
          cols="2"
        >
          <v-btn
            large
            block
            color="accent"
            @click="dialogDelete = !dialogDelete"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('messages.delete') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="accent"
            @click="onSubmitUpdateShift"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('messages.save') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="primary"
            @click="openDeleteDialog"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('messages.cancel') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <dialog-delete-shift
      :dialog-delete-shift="dialogDelete"
      @removeShift="deletePeriod"
      @closeDialogDelete="closeDelete"
    />
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'
  import { get, sync } from 'vuex-pathify'
  import { required } from 'vee-validate/dist/rules'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import VueHtml2pdf from 'vue-html2pdf'
  import SignaturePad from '../../components/SignaturePad'
  import CellEdit from '../../components/app/CellEdit'
  import CellEditTime from '../../components/app/CellEditTime'
  import DialogDeleteShift from '../../components/DialogDeleteShift'
  import { api } from '@/config/index'
  import axios from 'axios'

  export default {
    name: 'ShiftInformationDisplay',
    components: {
      DialogDeleteShift,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      SignaturePad,
      CellEdit,
      CellEditTime,
    },
    data () {
      return {
        dialogDelete: false,
        dialogCreateShift: false,
        editName: false,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        availableCustomers: [],
        selectedCustomer: '',
        dates: [],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        datesMenu: false,
        userType: 0,
        items: [
          {
            id: 2043,
            shift: {
              id: 1,
              job: {
                id: 1,
                name: 'Bartender',
              },
            },
          },
        ],
        search: '',
        headers: [],
        options: {},
        loading: false,
        check: true,
        nameFile: '',
        nowDay: moment(new Date()).format('YYYY-MM-DD'),
        shiftPeriodId: '',
        showPopupEdit: false,
        attendenceRecord: {},
        menu: false,
        timesheet: [],
        timesheetName: '',
        timesheetId: '',
        indexPeriod: 0,
        period: [],
        isPresent: true,
        inputActualStartAt: '',
        inputActualEndAt: '',
        inputBreaktime: '',
        startAtMenu: false,
        endAtMenu: false,
        isUpdate: false,
        signature: '',
        isSubmitted: false,
        nameCustomer: '',
        timesheetRecords: [],
        isOtNightShift: null,
      }
    },
    computed: {
      ...get('timesheet', ['message', 'status', 'error', 'timesheetPeriod']),
      ...get('shift', ['shiftPeriod']),
      ...get('user', ['profile']),
    },
    watch: {
      isPresent (value) {
        if (!value) {
          this.inputActualStartAt = '00:00'
          this.inputActualEndAt = '00:00'
          this.inputBreaktime = 0
        } else {
          this.inputActualStartAt = ''
          this.inputActualEndAt = ''
          this.inputBreaktime = ''
        }
      },
      async timesheetPeriod (value) {
        if (value) {
          const fullUrl = api.baseUrl + '/customer/' + value.data.timesheet.customerId
          const response = await axios.get(fullUrl)
          this.isOtNightShift = response.data.data.customer.isOtNightShift
          this.getHeaders()
          if (value.data.timesheet.submittedAt) {
            this.isSubmitted = true
          }
          this.timesheetName = value.data.timesheet.name
          this.date = value.data.timesheet.workdate
          this.signature = value.data.timesheet.signature
          this.timesheet = {
            customerId: value.data.timesheet.customerId,
            name: this.timesheetName,
            workdate: this.date,
            signature: this.signature,
            timesheetRecords: [],
          }
          value.data.timesheet.timesheetRecords.forEach(timesheetRecord => {
            this.timesheet.timesheetRecords.push({
              id: timesheetRecord.id,
              parttimeEmployeeManageId: timesheetRecord.attendenceRecord.parttimeEmployee.manageId,
              parttimeEmployeeName: timesheetRecord.attendenceRecord.parttimeEmployee.user.name,
              jobName: timesheetRecord.attendenceRecord.shift.job.name,
              isPresent: timesheetRecord.isPresent,
              actualStartAt: this.convertTimeStringUTC(this.date, timesheetRecord.actualStartAt),
              actualEndAt: this.convertTimeStringUTC(this.date, timesheetRecord.actualEndAt),
              breaktime: timesheetRecord.breaktime,
              normalWorktime: null,
              nightShiftWorktime: null,
              otWorktime: null,
              note: timesheetRecord.note,
              shift: timesheetRecord.attendenceRecord.shift,
            })
            this.timesheet.timesheetRecords = this.convertTimeTimesheet(this.timesheet.timesheetRecords)
            this.timesheet.timesheetRecords.forEach(item => {
              this.updateTimeSheetItem(item)
            })
          })
        }
      },
      error (value) {
        this.showSnackBar(value)
      },
      list (value) {
        this.availableCustomers = value.data.customers
      },
      selectedCustomer () {
        this.openCreateShift()
      },
      async shiftPeriod (value) {
        this.timesheet = []
        if (value) {
          const fullUrl = api.baseUrl + '/customer/' + value.customerId
          const response = await axios.get(fullUrl)
          this.isOtNightShift = response.data.data.customer.isOtNightShift
          this.getHeaders()
          this.timesheet = {
            customerId: value.customerId,
            name: this.timesheetName,
            workdate: this.date,
            signature: '',
            timesheetRecords: [],
          }
          value.attendenceRecords.forEach(attendenceRecord => {
            const record = attendenceRecord.values.find(value => value.workdate === this.date)
            if (record) {
              this.timesheet.timesheetRecords.push({
                id: record.id,
                parttimeEmployeeManageId: attendenceRecord.parttimeEmployeeManageId,
                parttimeEmployeeName: attendenceRecord.parttimeEmployeeName,
                jobName: attendenceRecord.jobName,
                shiftEndAt: attendenceRecord.shiftEndAt,
                shiftStartAt: attendenceRecord.shiftStartAt,
                isPresent: record.isPresent === null,
                worktimeType: attendenceRecord.worktimeType,
                actualStartAt: record.actualStartAt
                  ? this.convertTimeStringUTC(record.workdate, record.actualStartAt)
                  : this.convertTimeStringUTC(record.workdate, record.plannedStartAt),
                actualEndAt: record.actualEndAt
                  ? this.convertTimeStringUTC(record.workdate, record.actualEndAt)
                  : this.convertTimeStringUTC(record.workdate, record.plannedEndAt),
                breaktime: attendenceRecord.planBreakTime,
                normalWorktime: null,
                nightShiftWorktime: null,
                otWorktime: null,
                note: record.note,
              })
              this.timesheet.timesheetRecords = this.convertTimeTimesheet(this.timesheet.timesheetRecords)
              this.timesheet.timesheetRecords.forEach(item => {
                this.updateTimeSheetItem(item)
              })
            }
          })
        }
      },
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
          this.$router.push('/time-sheet/')
        } else if (value === 'updateSuccess') {
          const payload = {
            timesheetId: this.$route.query.timesheetId,
            timesheetRecords: this.timesheetRecords,
          }
          payload.timesheetRecords.forEach(timesheetRecord => {
            this.updateTimeSheetItem(timesheetRecord)
          })
          this.$store.dispatch('timesheet/submitTimesheet', payload)
        } else if (value === 'deleteSuccess') {
          this.$router.push({
            path: '/time-sheet/',
            query: { deleteSuccess: true },
          })
        }
      },
    },
    created () {
      this.$store.dispatch('user/getProfile')
      if (this.$route.query.timesheetId) {
        this.timesheetId = this.$route.query.timesheetId
        this.getTimesheetPeriod(this.$route.query.timesheetId)
        this.isUpdate = true
        this.nameCustomer = this.$route.query.nameCustomer
      } else {
        this.shiftPeriodId = this.$route.query.shiftPeriodId
        this.date = this.$route.query.workdate
        this.timesheetName = this.$route.query.name
        this.getPeriodShift()
        if (this.userType === 3) {
          this.nameCustomer = this.profile.name
        } else {
          this.nameCustomer = this.$route.query.customerName
        }
      }
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      }
    },
    mounted () {
      this.getHeaders()
      extend('checktime', {
        events: ['blur'],
        validate (value) {
          if (/^[0,1,2,3,4]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(value)) {
            const timeSplited = value.split(':')
            if (timeSplited[0] === 24) return false
            return true
          }
          return false
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      extend('checktime24h', {
        events: ['blur'],
        validate (value) {
          if (!moment(value, 'HH:mm').isValid()) return false
          return true
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
    },
    methods: {
      showErorr (text) {
        this.showSnackBar(text)
      },
      getHeaders () {
        if (this.isOtNightShift) {
          this.headers = [
            {
              text: this.$t('table.headers.index'),
              align: 'center',
              value: 'index',
              class: 'font-weight-bold',
              sortable: false,
              width: '70px',
            },
            {
              text: this.$t('table.headers.manageId'),
              align: 'center',
              value: 'manageId',
              class: 'font-weight-bold',
              sortable: false,
              width: '120px',
            },
            {
              text: this.$t('table.headers.name'),
              align: 'center',
              value: 'name',
              class: 'font-weight-bold',
              sortable: false,
              width: '120px',
            },
            {
              text: this.$t('table.headers.job'),
              align: 'center',
              value: 'job',
              class: 'font-weight-bold',
              sortable: false,
              width: '150px',
            },
            {
              text: this.$t('table.headers.attendanceConfirmation'),
              align: 'center',
              value: 'attendanceConfirmation',
              class: 'font-weight-bold',
              sortable: false,
              width: '120px',
            },
            {
              text: this.$t('table.headers.actualStartAt'),
              align: 'center',
              value: 'actualStartAt',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.actualEndAt'),
              align: 'center',
              value: 'actualEndAt',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.normalWorkTime'),
              align: 'center',
              value: 'normalWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.nightWorkTime'),
              align: 'center',
              value: 'nightShiftWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.workOvertime'),
              align: 'center',
              value: 'otWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.nightOtWorkTime'),
              align: 'center',
              value: 'otNightShiftWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.breakHour'),
              align: 'center',
              value: 'breakHour',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.workingHour'),
              align: 'center',
              value: 'totalWorkHour',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.note'),
              align: 'center',
              value: 'note',
              class: 'font-weight-bold',
              sortable: false,
            },
          ]
        } else {
          this.headers = [
            {
              text: this.$t('table.headers.index'),
              align: 'center',
              value: 'index',
              class: 'font-weight-bold',
              sortable: false,
              width: '70px',
            },
            {
              text: this.$t('table.headers.manageId'),
              align: 'center',
              value: 'manageId',
              class: 'font-weight-bold',
              sortable: false,
              width: '120px',
            },
            {
              text: this.$t('table.headers.name'),
              align: 'center',
              value: 'name',
              class: 'font-weight-bold',
              sortable: false,
              width: '120px',
            },
            {
              text: this.$t('table.headers.job'),
              align: 'center',
              value: 'job',
              class: 'font-weight-bold',
              sortable: false,
              width: '150px',
            },
            {
              text: this.$t('table.headers.attendanceConfirmation'),
              align: 'center',
              value: 'attendanceConfirmation',
              class: 'font-weight-bold',
              sortable: false,
              width: '120px',
            },
            {
              text: this.$t('table.headers.actualStartAt'),
              align: 'center',
              value: 'actualStartAt',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.actualEndAt'),
              align: 'center',
              value: 'actualEndAt',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.normalWorkTime'),
              align: 'center',
              value: 'normalWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.nightWorkTime'),
              align: 'center',
              value: 'nightShiftWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.workOvertime'),
              align: 'center',
              value: 'otWorktime',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.breakHour'),
              align: 'center',
              value: 'breakHour',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.workingHour'),
              align: 'center',
              value: 'totalWorkHour',
              class: 'font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.note'),
              align: 'center',
              value: 'note',
              class: 'font-weight-bold',
              sortable: false,
            },
          ]
        }
      },
      convertTime (number) {
        if (number) {
          let startTime = String(parseInt(number))
          let endTime = String(Math.round((number - parseInt(number)) * 60))
          if (startTime.length === 1) {
            startTime = '0' + startTime + ':'
          } else if (startTime.length === 2) {
            startTime += ':'
          }
          if (endTime.length === 1) {
            endTime = '0' + endTime
          }
          return startTime + endTime
        } else {
          return '00:00'
        }
      },
      deletePeriod () {
        this.$store.dispatch('timesheet/deletePeriod', this.timesheetId)
      },
      closeDelete () {
        this.dialogDelete = false
      },
      handleCalculatorTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) + 24)
        setTime += ':' + String(time.slice(3, 5))
        return setTime
      },
      convertTimeTimesheet (items) {
        this.items.forEach((item) => {
          if (
            moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = moment
              .utc(new Date(item.actualEndAt))
              .format('HH:mm')
          } else if (
            moment.utc(new Date(item.plannedStartAt)).add(2, 'days').format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = '48:00'
          } else {
            item.actualEndAt = this.handleCalculatorTime(moment.utc(new Date(item.actualEndAt)).format('HH:mm'))
          }
          if (
            moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualStartAt)).format('YYYY-MM-DD')
          ) {
            item.actualStartAt = moment
              .utc(new Date(item.actualStartAt))
              .format('HH:mm')
          } else if (
            moment.utc(new Date(item.plannedStartAt)).add(2, 'days').format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualStartAt)).format('YYYY-MM-DD')
          ) {
            item.actualStartAt = '48:00'
          } else {
            item.actualStartAt = this.handleCalculatorTime(moment.utc(new Date(item.actualStartAt)).format('HH:mm'))
          }
        })
        return _.cloneDeep(items)
      },
      handleCheckTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) - 24)
        if (setTime.length === 1) {
          setTime = '0' + setTime + ':'
        } else if (setTime.length === 2) {
          setTime += ':'
        }
        setTime += String(time.slice(3, 5))
        return setTime
      },
      saveNote (item) {
        const payload = {
          value: event.target.value,
          item: item,
          prop: 'note',
        }
        this.save(payload)
      },
      save (payload) {
        if (payload.prop === 'actualStartAt') {
          const timeFirstStart = parseInt(payload.value.slice(0, 2))
          const timeLastStart = parseInt(payload.value.slice(3, 5))
          const timeFirstEnd = parseInt(payload.item.actualEndAt.slice(0, 2))
          const timeLastEnd = parseInt(payload.item.actualEndAt.slice(3, 5))
          if (timeFirstEnd === 0 && timeLastEnd === 0) {
            payload.item.actualEndAt = ''
          } else {
            if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
              return
            }
          }
        } else if (payload.prop === 'actualEndAt') {
          const timeFirstStart = parseInt(payload.item.actualStartAt.slice(0, 2))
          const timeLastStart = parseInt(payload.item.actualStartAt.slice(3, 5))
          const timeFirstEnd = parseInt(payload.value.slice(0, 2))
          const timeLastEnd = parseInt(payload.value.slice(3, 5))
          if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
            return
          }
        }
        payload.item[payload.prop] = _.cloneDeep(payload.value)
        this.updateTimeSheetItem(payload.item)
      },
      checkActualAt (timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd) {
        let error = false
        if (timeFirstStart > timeFirstEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        } else if (timeFirstStart === timeFirstEnd && timeLastStart > timeLastEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        }
        return error
      },
      checkIsPresent (item) {
        if (item.isPresent === null) {
          item.isPresent = false
        } else {
          item.isPresent = !item.isPresent
        }
      },
      convertTimeStringUTC (workDate, time) {
        let stringTime = moment.utc(new Date(time)).format('HH:mm')
        if (
          moment.utc(new Date(workDate)).format('YYYY-MM-DD') !==
          moment.utc(new Date(time)).format('YYYY-MM-DD')
        ) {
          stringTime = this.handleCalculatorTime(stringTime)
        }
        return stringTime
      },
      getSignature (signature) {
        if (signature) {
          this.signature = signature
        } else {
          this.signature = ''
        }
      },
      handleCheck () {
        if (this.checkTime(this.inputActualStartAt) || this.checkTime(this.inputActualEndAt)) {
          this.inputActualStartAt = ''
          this.inputActualEndAt = ''
        }
      },
      checkTime (time) {
        if (!(/^[0,1,2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(time))) {
          return true
        }
        return false
      },
      getTimesheetPeriod (timesheetId) {
        const payload = {
          id: timesheetId,
        }
        this.$store.dispatch('timesheet/getTimesheetPeriod', payload)
      },
      getPeriodShift () {
        this.$store.dispatch('shift/getDetailPeriodShift', {
          id: this.shiftPeriodId,
          workdate: this.date,
        })
      },
      getNameCustomer (customer) {
        return _.get(customer, 'code', '')
          ? _.get(customer, 'code', '') + ' - ' + _.get(customer, 'name', '')
          : _.get(customer, 'name', '')
      },
      openCreateShift () {
        if (
          this.selectedCustomer &&
          this.dates.length > 0 &&
          !this.shiftPeriodId
        ) {
          const customer = this.availableCustomers.find(
            item => this.selectedCustomer === item.id,
          )
          const date = this.dates[0]

          const textDate = moment(date).format('YYYY年MM月DD日')

          this.nameFile = customer.name + '_' + textDate + '_' + '出勤簿'
          this.dialogCreateShift = true
        }
      },
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      onSubmitUpdateShift () {
        let isCheck = false
        let isIssetActualAt = true
        if (this.isUpdate) {
          const payload = this.userType === 3 ? {
            timesheetId: this.timesheetId,
            signature: this.signature,
          } : {
            timesheetId: this.$route.query.timesheetId,
            customerId: this.timesheet.customerId,
            name: this.timesheetName,
            workdate: this.date,
            timesheetRecords: [],
          }
          this.timesheet.timesheetRecords.forEach(timesheetRecord => {
            if (this.checkBreakTime(timesheetRecord)) {
              isCheck = true
            }
            let startAt = timesheetRecord.isPresent !== false ? timesheetRecord.actualStartAt : '00:00'
            let endAt = timesheetRecord.isPresent !== false ? timesheetRecord.actualEndAt : '00:00'
            let plannedStartAt = this.date
            let plannedEnd = this.date

            if (parseInt(startAt.slice(0, 2)) >= 24) {
              startAt = this.handleCheckTime(startAt)
              plannedStartAt = moment.utc(new Date(this.date)).add(1, 'days').format('YYYY-MM-DD')
            }

            if (parseInt(endAt.slice(0, 2)) >= 24) {
              endAt = this.handleCheckTime(endAt)
              plannedEnd = moment.utc(new Date(this.date)).add(1, 'days').format('YYYY-MM-DD')
            }
            if (!timesheetRecord.actualStartAt) {
              isIssetActualAt = false
            }

            if (!timesheetRecord.actualEndAt) {
              isIssetActualAt = false
            }
            if (this.userType !== 3) {
              payload.timesheetRecords.push({
                id: timesheetRecord.id,
                isPresent: timesheetRecord.isPresent,
                actualStartAt: moment.utc(new Date(plannedStartAt)).format('YYYY-MM-DD') +
                  'T' +
                  startAt,
                actualEndAt: moment.utc(new Date(plannedEnd)).format('YYYY-MM-DD') +
                  'T' +
                  endAt,
                normalWorktime: timesheetRecord.isPresent !== false ? timesheetRecord.normalWorktime : 0,
                nightShiftWorktime: timesheetRecord.isPresent !== false ? timesheetRecord.nightShiftWorktime : 0,
                otWorktime: timesheetRecord.isPresent !== false ? timesheetRecord.otWorktime : 0,
                totalWorkHour: timesheetRecord.isPresent !== false ? timesheetRecord.totalWorkHour : 0,
                breaktime: timesheetRecord.isPresent !== false ? timesheetRecord.breaktime : 0,
                otNightShiftWorktime: (timesheetRecord.isPresent !== false && this.isOtNightShift) ? timesheetRecord.otNightShiftWorktime : 0,
                note: timesheetRecord.isPresent !== false ? timesheetRecord.note : null,
              })
            }
          })
          if (!isIssetActualAt) {
            this.showSnackBar(this.$t('error.bad_input'))
            return
          }
          if (!isCheck) {
            this.timesheetRecords = payload.timesheetRecords
            this.$store.dispatch('timesheet/submitTimesheet', payload)
          } else {
            this.showSnackBar(this.$t('error.breakTime'))
          }
        } else {
          const payload = this.userType === 3 ? {
            signature: this.signature,
          } : {
            customerId: this.timesheet.customerId,
            name: this.timesheetName,
            workdate: this.date,
            attendenceRecords: [],
          }
          this.timesheet.timesheetRecords.forEach(timesheetRecord => {
            if (this.checkBreakTime(timesheetRecord)) {
              isCheck = true
            }
            let startAt = timesheetRecord.isPresent !== false ? timesheetRecord.actualStartAt : '00:00'
            let endAt = timesheetRecord.isPresent !== false ? timesheetRecord.actualEndAt : '00:00'
            if (!timesheetRecord.actualStartAt) {
              isIssetActualAt = false
            }

            if (!timesheetRecord.actualEndAt) {
              isIssetActualAt = false
            }
            let plannedStartAt = this.date
            let plannedEndAt = this.date

            if (parseInt(startAt.slice(0, 2)) >= 24) {
              startAt = this.handleCheckTime(startAt)
              plannedStartAt = moment.utc(new Date(plannedStartAt)).add(1, 'days').format('YYYY-MM-DD')
            }

            if (parseInt(endAt.slice(0, 2)) >= 24) {
              endAt = this.handleCheckTime(endAt)
              plannedEndAt = moment.utc(new Date(plannedEndAt)).add(1, 'days').format('YYYY-MM-DD')
            }
            if (this.userType !== 3) {
              payload.attendenceRecords.push({
                id: timesheetRecord.id,
                isPresent: timesheetRecord.isPresent,
                actualStartAt: moment.utc(new Date(plannedStartAt)).format('YYYY-MM-DD') +
                  'T' +
                  startAt,
                actualEndAt: moment.utc(new Date(plannedEndAt)).format('YYYY-MM-DD') +
                  'T' +
                  endAt,
                normalWorktime: timesheetRecord.isPresent !== false ? timesheetRecord.normalWorktime : 0,
                nightShiftWorktime: timesheetRecord.isPresent !== false ? timesheetRecord.nightShiftWorktime : 0,
                otWorktime: timesheetRecord.isPresent !== false ? timesheetRecord.otWorktime : 0,
                totalWorkHour: timesheetRecord.isPresent !== false ? timesheetRecord.totalWorkHour : 0,
                breaktime: timesheetRecord.isPresent !== false ? timesheetRecord.breaktime : 0,
                otNightShiftWorktime: (timesheetRecord.isPresent !== false && this.isOtNightShift) ? timesheetRecord.otNightShiftWorktime : 0,
                note: timesheetRecord.isPresent !== false ? timesheetRecord.note : null,
              })
            }
          })
          if (!isIssetActualAt) {
            this.showSnackBar(this.$t('error.bad_input'))
            return
          }
          if (!isCheck) {
            // payload.attendenceRecords.forEach(timesheetRecord => {
            //   this.updateTimeSheetItem(timesheetRecord)
            // })
            this.$store.dispatch('timesheet/createTimesheet', payload)
          } else {
            this.showSnackBar(this.$t('error.breakTime'))
          }
        }
      },
      openDeleteDialog () {
        if (this.isUpdate) {
          this.$router.push('/time-sheet/')
        } else {
          this.$router.push('/shift-list/')
        }
      },
      handleSubmit () {
        this.onSubmitUpdateShift()
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      close () {
        this.dialogCreateShift = false
        this.dialogDelete = false
      },
      closeCreate () {
        this.nameFile = ''
        this.dialogCreateShift = false
      },
      // Popup Edit
      handleClick (row, index) {
        this.period = row
        this.indexPeriod = index - 1
        if (this.period) {
          this.isPresent = this.period.isPresent
          this.inputActualStartAt = this.period.actualStartAt
          this.inputActualEndAt = this.period.actualEndAt
          this.inputBreaktime = this.period.breaktime
        }
        this.showPopupEdit = true
      },
      handleUpdatePeriod () {
        if (!this.checkValidate()) {
          const arr = this.timesheet
          arr.timesheetRecords[this.indexPeriod].isPresent = this.isPresent
          arr.timesheetRecords[this.indexPeriod].actualStartAt = this.inputActualStartAt
          arr.timesheetRecords[this.indexPeriod].actualEndAt = this.inputActualEndAt
          arr.timesheetRecords[this.indexPeriod].breaktime = this.inputBreaktime
          this.timesheet = arr
          this.reload()
          this.showPopupEdit = false
        } else {
          this.showSnackBar('messages.updateSuccess')
        }
      },
      checkValidate () {
        let error = false
        if (this.inputActualStartAt.length === 0) {
          error = true
        }

        if (this.inputActualStartAt.length === 0) {
          error = true
        }

        if (this.inputBreaktime.length === 0) {
          error = true
        }

        return error
      },
      reload () {
        this.isPresent = true
        this.inputActualStartAt = ''
        this.inputActualEndAt = ''
        this.inputBreaktime = ''
        this.indexPeriod = ''
      },
      convertDate (item) {
        return moment.utc(item).format('HH:mm')
      },
      closeshowPopupEdit () {
        this.showPopupEdit = false
      },
      checkBreakTime (item) {
        let isCheck = false
        const totalActual = parseFloat(this.converNumber(item.actualEndAt) - parseFloat(this.converNumber(item.actualStartAt)))
        if (totalActual < item.breaktime) {
          isCheck = true
        }
        return isCheck
      },
      converNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = (parseInt(str.slice(3, 5)) / 60)

        if (timeStart) {
          return parseFloat(timeStart + timeEnd).toFixed(2)
        } else if (timeEnd) {
          return parseFloat('0' + timeEnd).toFixed(2)
        } else {
          return 0
        }
      },
      checkShiftDay (time) {
        return (time > 5 && time < 22) || (time > 29 && time < 46)
      },
      updateTimeSheetItem (item) {
        let startTime = this.converNumber(item.actualStartAt)
        let endTime = this.converNumber(item.actualEndAt)
        let workTime = 0
        let otTime = 0
        let otNightShift = 0

        workTime = endTime - startTime

        if (parseFloat(startTime) > parseFloat(endTime)) {
          workTime = parseFloat(endTime) + 24 - startTime
        }

        if (parseFloat(startTime) > 24) {
          startTime = parseFloat(startTime) - 24
        }

        if (parseFloat(endTime) > 24) {
          endTime = parseFloat(endTime) - 24
        }

        if (workTime > 8) {
          otTime = workTime - 8
          workTime = 8
        }

        if (this.checkShiftDay(parseFloat(startTime)) && !this.checkShiftDay(parseFloat(startTime) + workTime)) {
          item.normalWorktime = 22 - startTime
          item.nightShiftWorktime = workTime - item.normalWorktime
        }

        if (!this.checkShiftDay(parseFloat(startTime)) && this.checkShiftDay(parseFloat(startTime) + workTime)) {
          if (startTime <= 5) {
            item.nightShiftWorktime = 5 - startTime
            item.normalWorktime = workTime - item.nightShiftWorktime
          }

          if (startTime >= 22) {
            item.nightShiftWorktime = 29 - startTime
            item.normalWorktime = workTime - item.nightShiftWorktime
          }
        }

        if (this.checkShiftDay(parseFloat(startTime)) && this.checkShiftDay(parseFloat(startTime) + workTime)) {
          if (parseFloat(startTime) < 22 && this.converNumber(item.actualEndAt) > 29) {
            item.normalWorktime = 22 - parseFloat(startTime) + parseFloat(this.converNumber(item.actualEndAt)) - 29
            item.nightShiftWorktime = 7
          } else {
            item.normalWorktime = workTime
            item.nightShiftWorktime = 0
          }
        }

        if (!this.checkShiftDay(parseFloat(startTime)) && !this.checkShiftDay(parseFloat(startTime) + workTime)) {
          item.nightShiftWorktime = workTime
          item.normalWorktime = 0
        }
        const worktimeType = this.getValueObject(item, 'shift.worktimeType') ? this.getValueObject(item, 'shift.worktimeType') : this.getValueObject(item, 'worktimeType')

        item.checkBreakTime = true

        if (worktimeType) {
          if (item.breaktime > item.normalWorktime) {
            item.checkBreakTime = false
            this.showSnackBar(this.$t('error.breakTime'))
          }
          item.normalWorktime = item.normalWorktime - item.breaktime
        }

        if (!worktimeType) {
          if (item.breaktime > item.nightShiftWorktime) {
            item.checkBreakTime = false
            this.showSnackBar(this.$t('error.breakTime'))
          }
          item.nightShiftWorktime = item.nightShiftWorktime - item.breaktime
        }
        const breaktime = item.breaktime ? parseFloat(item.breaktime) : 0
        if (otTime > 0 && this.isOtNightShift) {
          const start = parseFloat(this.converNumber(item.actualStartAt))
          const end = parseFloat(this.converNumber(item.actualEndAt))
          if (start < 5 || (start >= 22 && start < 29) || start >= 46) {
            item.normalWorktime += breaktime
          } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
            if (((start + 8 >= 22 && start + 8 < 29) || (start + 8) >= 46) && (item.nightShiftWorktime + breaktime) < 7) {
              item.nightShiftWorktime += breaktime
            } else {
              item.normalWorktime += breaktime
            }
          }
          const time = start + item.normalWorktime + item.nightShiftWorktime + breaktime
          if (end > 5 && end <= 22) {
            otNightShift = 0
          } else if (end > 22 && end <= 29) {
            if (time <= 22) {
              otNightShift = end - 22
            } else {
              otNightShift = end - time
            }
          } else if (end > 29 && end <= 46) {
            if (time <= 22) {
              otNightShift = end - 22 - (end - 29)
            } else {
              otNightShift = end - time - (end - 29)
            }
          } else if (end > 46) {
            if (time <= 22) {
              otNightShift = end - 22 - (end - 29) + (end - 46)
            } else {
              otNightShift = end - time - (end - 29) + (end - 46)
            }
          }
          otTime -= otNightShift + breaktime
          if (otTime < 0 && !otNightShift) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otTime = 0
            } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
              item.normalWorktime += otTime
              otTime = 0
            }
          } else if (otTime < 0 && otNightShift) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otNightShift += otTime
              otTime = 0
            } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
              if (item.nightShiftWorktime > 0) {
                otNightShift += otTime
                otTime = 0
              } else {
                otNightShift += otTime
                otTime = 0
              }
            }
          } else if (otNightShift < 0) {
            otTime += otNightShift
            otNightShift = 0
          }
        } else if (otTime > 0 && !this.isOtNightShift) {
          const start = parseFloat(this.converNumber(item.actualStartAt))
          if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
            item.normalWorktime += breaktime
          } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
            if (((start + 8 >= 22 && start + 8 < 29) || (start + 8) >= 46) && (item.nightShiftWorktime + breaktime) < 7) {
              item.nightShiftWorktime += breaktime
            } else {
              item.normalWorktime += breaktime
            }
          }
          otTime -= breaktime
          if (otTime < 0) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otTime = 0
            } else if ((start >= 5 && start <= 22) || (start >= 29 && start < 46)) {
              item.normalWorktime += otTime
              otTime = 0
            }
          }
        } else {
          otNightShift = 0
        }
        item.otWorktime = otTime
        item.otNightShiftWorktime = otNightShift
        item.totalWorkHour = parseFloat(item.normalWorktime) + parseFloat(item.otWorktime) + parseFloat(item.nightShiftWorktime) + parseFloat(item.otNightShiftWorktime)
        item.totalWorkHour = item.totalWorkHour ? parseFloat(item.totalWorkHour).toFixed(2) : 0
        item.normalWorktime = parseFloat(item.normalWorktime).toFixed(2)
        item.nightShiftWorktime = item.nightShiftWorktime ? parseFloat(item.nightShiftWorktime).toFixed(2) : 0
        item.otWorktime = parseFloat(item.otWorktime).toFixed(2)
        item.otNightShiftWorktime = (this.isOtNightShift && item.otNightShiftWorktime) ? parseFloat(item.otNightShiftWorktime).toFixed(2) : 0
      },
    },
  }
</script>
<style lang="scss">
  .nameFile {
    margin-top: 20px;
  }

  .textJob {
    text-align: center;
    color: #4262ff;
  }
  .text-label {
    font-size: 20px;
    color: #5ca6d2;
    font-weight: bold;
  }
  tbody tr td {
    cursor: pointer;
  }
  .highlights {
    color: #E43E08
  }
  .tableSign {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    padding: 10px;
    td,
    th {
      border: 1px solid #2d2d2d;
      padding: 8px;
      text-align: center;
      width: 100px;
    }
    tr {
      height: 100px;
    }
  }
  img {
    border: 1px solid;
    width: 200px;
  }
  .attendancConfirm {
    color: #E43E08
  }
  .errorBreakTime {
    color: #E43E08
  }
</style>
